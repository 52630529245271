import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Image from 'next/image'
import { useRouter } from 'next/router'
import Favorite from '../Favorite'

export default function CollectionWidget({ collection, dataProps }) {
  const { t } = useTranslation('translation', {
    useSuspense: false,
    bindI18n: false,
  })

  const src = collection.image.square

  const router = useRouter()

  const redirectToSingleView = (e) => {
    if (!e.target.parentElement.dataset.heart) {
      router.push({
        pathname: `/exercise/collections/${collection.id}`,
      })
    }
  }

  return (
    <div
      {...dataProps}
      className="mx-1 flex h-full cursor-pointer flex-col justify-start p-2 transition-transform sm:hover:scale-105 lg:hover:scale-105 xl:hover:scale-105 2xl:hover:scale-105"
      onClick={redirectToSingleView}>
      <div className="relative mb-2 rounded-xl shadow-xl">
        <div className="reset-nextjs-inline-styles">
          <Image
            src={src}
            priority
            alt="Collection cover picture"
            width="288"
            height="288"
            className="rounded-xl"
          />
        </div>
        <p className="absolute top-3 right-3">
          <Favorite
            active={collection.listed}
            id={collection.id}
            type="WorkoutCollection"
          />
        </p>
      </div>
      <p className="text-md truncate font-link text-ar-dark-gray">
        {collection.title}
      </p>
      <p className="truncate font-body text-sm text-ar-light-gray text-opacity-60">
        {t('widgets.workouts', { count: collection.workoutsCount })}
      </p>
    </div>
  )
}

CollectionWidget.propTypes = {
  collection: PropTypes.object.isRequired,
  dataProps: PropTypes.object,
}
