import { gql } from '@apollo/client'

export const MY_FAVORITE_COLLECTIONS_QUERY = gql`
  query myFavoriteCollections {
    collectionsList(type: "WorkoutCollection") {
      collections {
        id
        title
        listed
        workoutsCount
        image {
          square
        }
      }
    }
  }
`
