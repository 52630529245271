import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import useInView from 'react-cool-inview'

export default function LazyGraphQL({ children, ...delegated }) {
  const [hasMounted, setHasMounted] = useState(false)
  const [height, setHeight] = useState('h-96')

  const { observe, inView } = useInView({
    onEnter: ({ unobserve }) => {
      setHeight('')
      unobserve()
    },
  })

  useEffect(() => {
    setHasMounted(true)
  }, [])

  if (!hasMounted) {
    return null
  }

  return (
    <div className={`relative ${height}`} ref={observe} {...delegated}>
      {inView && children}
    </div>
  )
}

LazyGraphQL.propTypes = {
  children: PropTypes.node.isRequired,
}
