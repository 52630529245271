import PropTypes from 'prop-types'
import { useState } from 'react'
import * as Sentry from '@sentry/nextjs'
import useApi from '../../hooks/useApi'
import EmptyHeart from '../../public/icons/heart-empty.svg'
import FilledHeart from '../../public/icons/heart-filled.svg'

const CONTENT_TYPE_MAPPING = {
  'V2::Workout': 'workout',
  WorkoutCollection: 'collection',
}

export default function Favorite({ active, id, type }) {
  const [favorite, setFavorite] = useState(active)
  const { addToFavorites, removeFromFavorites } = useApi()

  const dataProps = {
    'data-tracking-event-type': favorite
      ? 'removeFromLibrary'
      : 'saveToLibrary',
    'data-tracking-content-type': CONTENT_TYPE_MAPPING[type],
    'data-tracking-content-id': id,
  }

  const createFavorite = async (listableType, listableId) => {
    try {
      const listItem = await addToFavorites({
        listableType,
        listableId,
      })

      if (listItem.id !== undefined) {
        setFavorite(true)
      }
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const removeFavorite = async (listableType, listableId) => {
    try {
      const listItem = await removeFromFavorites({
        listableType,
        listableId,
      })

      if (listItem.id !== undefined) {
        setFavorite(false)
      }
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  return favorite ? (
    <FilledHeart
      {...dataProps}
      onClick={() => removeFavorite(type, id)}
      data-heart="true"
    />
  ) : (
    <EmptyHeart
      {...dataProps}
      onClick={() => createFavorite(type, id)}
      data-heart="true"
    />
  )
}

Favorite.propTypes = {
  active: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
}
