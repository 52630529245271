import PropTypes from 'prop-types'
import { useRouter } from 'next/router'
import Image from 'next/image'
import { useTranslation } from 'react-i18next'
import getConfig from 'next/config'
import { useMemo } from 'react'
import useApi from '../../hooks/useApi'
import Favorite from '../Favorite'
import PlayButtonWidget from '../../public/icons/play-button-widget.svg'
import { useUser } from '../../context/UserContext'

export default function WorkoutWidget({
  workout,
  dataProps,
  playbackSource,
  playbackSourceId,
}) {
  const { t } = useTranslation('translation', {
    useSuspense: false,
    bindI18n: false,
  })

  const { publicRuntimeConfig } = getConfig()
  const [user] = useUser()

  const { subscription } = user

  const isMobile = useMemo(() => {
    const { innerWidth: width } = window
    const mdScreenSize = 768

    return width < mdScreenSize
  }, [])

  const intensities = {
    1: t('widgets.intensities.low'),
    2: t('widgets.intensities.medium'),
    3: t('widgets.intensities.high'),
  }

  const src = workout.image.landscape
  const router = useRouter()

  const { magicLoginToken } = useApi()

  const displayFreeLabel = () => workout.free && !subscription && !isMobile

  const onClick = async (e) => {
    // widget eats all the clicks, this makes the fav heart trigger
    if (e.target.parentElement.dataset.heart) {
      e.preventDefault()
    } else if ((workout.free || subscription) && !isMobile) {
      router.push({
        pathname: '/watch/[wid]',
        query: {
          wid: workout.id,
          source: playbackSource,
          sourceId: playbackSourceId,
        },
      })
    } else if (!subscription) {
      const { login_token } = await magicLoginToken()
      router.push({
        pathname: `${publicRuntimeConfig.webFunnelUrl}/magic-login`,
        query: { token: login_token },
      })
    } else if (isMobile) {
      router.push('/download-the-app')
    }
  }

  return (
    <div
      {...dataProps}
      className="group flex h-full cursor-pointer flex-col justify-start p-2 transition-transform sm:hover:scale-105"
      onClick={onClick}>
      <div className="relative mb-2 rounded-xl shadow-xl">
        <div className="reset-nextjs-inline-styles">
          <Image
            className="rounded-xl object-cover"
            src={src}
            alt="Workout cover picture"
            width={350}
            height={175}
          />
        </div>
        <span className="absolute top-3 right-3">
          <Favorite
            active={workout.listed}
            id={workout.id}
            type="V2::Workout"
          />
        </span>
        <div className="absolute top-1/2 left-1/2 flex h-14 w-14 -translate-x-1/2 -translate-y-1/2 transform justify-center rounded-full bg-white text-black opacity-0 transition-opacity group-hover:opacity-50">
          <PlayButtonWidget className="self-center align-middle" />
        </div>
        {displayFreeLabel() && (
          <p className="absolute top-3 left-3 rounded bg-ar-dark-green p-1 font-link text-xs uppercase text-white">
            {t('widgets.free')}
          </p>
        )}
        <p className="absolute bottom-3 right-3 rounded bg-black bg-opacity-50 p-1 font-link text-xs uppercase text-white">
          {workout.duration} {t('widgets.minutes_abbreviation')}
        </p>
      </div>
      <p className="text-md truncate font-link text-ar-dark-gray">
        {workout.title}
      </p>
      <div className="flex space-x-2 font-body text-sm text-ar-light-gray text-opacity-60">
        <span>
          {workout.trainer ? workout.trainer.name : ''} •{' '}
          {intensities[workout.intensity]}
        </span>
      </div>
    </div>
  )
}

WorkoutWidget.propTypes = {
  workout: PropTypes.object.isRequired,
  dataProps: PropTypes.object,
  playbackSource: PropTypes.string,
  playbackSourceId: PropTypes.string,
}
