import PropTypes from 'prop-types'
import { useEffect } from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

export default function RowContent({ children }) {
  useEffect(() => {
    window.addEventListener('touchmove', preventDefault, { passive: false })
  }, [])

  const preventDefault = (e) => {
    if (e.target.tagName === 'IMG') {
      e.preventDefault()
      e.returnValue = false

      return false
    }
  }

  const responsive = {
    xxl: {
      breakpoint: {
        max: 3000,
        min: 1400,
      },
      items: 5,
      partialVisibilityGutter: 10,
      slidesToSlide: 5,
    },
    xl: {
      breakpoint: {
        max: 1400,
        min: 1021,
      },
      items: 4,
      partialVisibilityGutter: 10,
      slidesToSlide: 4,
    },
    l: {
      breakpoint: {
        max: 1021,
        min: 790,
      },
      items: 3,
      partialVisibilityGutter: 30,
      slidesToSlide: 3,
    },
    m: {
      breakpoint: {
        max: 790,
        min: 550,
      },
      items: 2,
      partialVisibilityGutter: 30,
      slidesToSlide: 2,
    },
    s: {
      breakpoint: {
        max: 550,
        min: 0,
      },
      items: 1,
      partialVisibilityGutter: 30,
      slidesToSlide: 1,
    },
  }

  return (
    <Carousel
      removeArrowOnDeviceType={['m', 's', 'l']}
      containerClass="container navCarousel"
      minimumTouchDrag={80}
      partialVisible={children.length > 1}
      infinite={children.length > 1}
      responsive={responsive}
      swipeable
      draggable>
      {children}
    </Carousel>
  )
}

RowContent.propTypes = {
  children: PropTypes.node.isRequired,
}
