import { useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { MY_FAVORITE_WORKOUTS_QUERY } from '../../graphql/queries/myFavoriteWorkouts'
import RowContent from '../RowContent'
import RowHeading from '../RowHeading'
import WorkoutWidget from '../widgets/Workout'
import WorkoutSkeleton from '../WorkoutSkeleton'

const REDIRECT_PATH = '/exercise/my-favorite-workouts'

export default function MyFavoriteWorkouts({ hideWhenNoFavorites }) {
  const { t } = useTranslation('translation', {
    useSuspense: false,
    bindI18n: false,
  })

  const { data, loading, error } = useQuery(MY_FAVORITE_WORKOUTS_QUERY)

  const dataProps = (workoutId) => {
    return {
      'data-tracking-event-type': 'startClick',
      'data-tracking-element-type': 'workoutDiscovery',
      'data-tracking-element-name': 'favourite_workouts',
      'data-tracking-content-type': 'workout',
      'data-tracking-content-id': workoutId,
    }
  }

  const renderHeading = () => {
    return (
      <RowHeading
        redirectPath={REDIRECT_PATH}
        contentLength={myFavorites?.workouts?.length}>
        {t('row_headers.my_favorite_workouts')}
      </RowHeading>
    )
  }

  if (loading) {
    return (
      <>
        {renderHeading()}
        <WorkoutSkeleton />
      </>
    )
  }

  if (error) return <p>ERROR: {error.message}</p>

  const myFavorites = data.workoutsList

  const contentPresent = () => myFavorites?.workouts?.length > 0

  if (hideWhenNoFavorites && !contentPresent()) {
    return <></>
  } else {
    return (
      <>
        {renderHeading()}
        {!contentPresent() && (
          <p className="font-body text-ar-dark-gray">
            {t('favorite_workouts.no_favorites')}
          </p>
        )}
        {contentPresent() && (
          <RowContent>
            {myFavorites.workouts.map((workout) => {
              if (workout?.image?.landscape) {
                return (
                  <WorkoutWidget
                    workout={workout}
                    key={workout.id}
                    playbackSource="favorites"
                    dataProps={dataProps(workout.id)}
                  />
                )
              }
            })}
          </RowContent>
        )}
      </>
    )
  }
}

MyFavoriteWorkouts.defaultProps = {
  hideWhenNoFavorites: false,
}

MyFavoriteWorkouts.propTypes = {
  hideWhenNoFavorites: PropTypes.bool,
}
