import { gql } from '@apollo/client'

export const MY_FAVORITE_WORKOUTS_QUERY = gql`
  query myFavoriteWorkouts {
    workoutsList {
      workouts {
        id
        free
        title
        duration
        intensity
        listed
        image {
          landscape
        }
        trainer {
          name
        }
      }
    }
  }
`
