import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import RowContent from '../RowContent'
import RowHeading from '../RowHeading'
import { MY_FAVORITE_COLLECTIONS_QUERY } from '../../graphql/queries/myFavoriteCollections'
import CollectionWidget from '../widgets/Collection'
import CollectionSkeleton from '../CollectionSkeleton'

const REDIRECT_PATH = '/exercise/my-favorite-collections'

export default function MyFavoriteCollections({ hideWhenNoFavorites }) {
  const { t } = useTranslation('translation', {
    useSuspense: false,
    bindI18n: false,
  })

  const { data, loading, error } = useQuery(MY_FAVORITE_COLLECTIONS_QUERY)

  const renderHeading = () => (
    <RowHeading
      redirectPath={REDIRECT_PATH}
      contentLength={myFavorites?.collections?.length}>
      {t('row_headers.my_favorite_workout_collections')}
    </RowHeading>
  )

  const dataProps = (collectionId) => {
    return {
      'data-tracking-event-type': 'startClick',
      'data-tracking-element-type': 'workoutDiscovery',
      'data-tracking-element-name': 'favourite_workout_collections',
      'data-tracking-content-type': 'collection',
      'data-tracking-content-id': collectionId,
    }
  }

  if (loading) {
    return (
      <>
        {renderHeading()}
        <CollectionSkeleton />
      </>
    )
  }

  if (error) return <p>ERROR: {error.message}</p>

  const myFavorites = data.collectionsList

  const contentPresent = () => myFavorites?.collections?.length > 0

  if (hideWhenNoFavorites && !contentPresent()) {
    return <></>
  } else {
    return (
      <>
        {renderHeading()}
        {!contentPresent() && (
          <p className="font-body text-ar-dark-gray">
            {t('favorite_workouts.no_favorites')}
          </p>
        )}
        {contentPresent() && (
          <RowContent>
            {myFavorites.collections.map((collection) => (
              <CollectionWidget
                collection={collection}
                key={collection.id}
                dataProps={dataProps(collection.id)}
              />
            ))}
          </RowContent>
        )}
      </>
    )
  }
}

MyFavoriteCollections.defaultProps = {
  hideWhenNoFavorites: false,
}

MyFavoriteCollections.propTypes = {
  hideWhenNoFavorites: PropTypes.bool,
}
