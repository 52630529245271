import RowContent from '../RowContent'

export default function WorkoutSkeleton() {
  const nPlaceholders = 6

  return (
    <RowContent>
      {[...Array(nPlaceholders)].map((_, index) => (
        <div className="flex h-full w-full flex-col space-y-2 p-2" key={index}>
          <div className="h-52 w-full rounded-lg bg-gradient-to-r from-gray-50 to-gray-200 sm:h-32" />
          <div className="h-5 w-5/6 rounded bg-gradient-to-r from-gray-50 to-gray-200" />
          <div className="h-3 w-3/4 rounded bg-gradient-to-r from-gray-50 to-gray-200" />
        </div>
      ))}
    </RowContent>
  )
}
